import {FC, Fragment} from 'react';
import {Route, Routes} from 'react-router';
import styled from 'styled-components';

import {FrontendName} from '@shared/frontends/frontend_constant';

import {useRoute} from '@shared-frontend/components/core/use_route';
import {useSharedRoutes} from '@shared-frontend/components/shared_routes';
import {useComponentClass} from '@shared-frontend/lib/react';

import {CartesMenusSection} from '@src/components/cartes_menus_section';
import {ContactAccesSection} from '@src/components/contact_acces_section';
import {NavBar} from '@src/components/nav_bar';
import {OutsidePhotoSection} from '@src/components/outside_photo_section';
import {PlatePhotosSection} from '@src/components/plate_photos_section';
import {RestaurantPresentationSection} from '@src/components/restaurant_presentation_section';
import {TopSection} from '@src/components/top_section';

export const App: FC = () => {
  const seo = {
    title: 'Restaurant',
    description: 'Une cuisine généreuse et simple élaboré à base de produits frais',
  };
  return (
    <Wrapper
      // eslint-disable-next-line react/forbid-component-props
      id="top"
    >
      <NavBar />
      <Routes>
        {useSharedRoutes<FrontendName.Restaurant>({seo, userDataForm: undefined})}
        <Route
          path="*"
          element={useRoute(
            useComponentClass(
              <Fragment>
                <TopSection />
                <RestaurantPresentationSection />
                <PlatePhotosSection />
                <CartesMenusSection />
                <OutsidePhotoSection />
                <ContactAccesSection />
              </Fragment>
            ),
            seo
          )}
        ></Route>
      </Routes>
    </Wrapper>
  );
};
App.displayName = 'App';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-color: #ffffff;
`;
