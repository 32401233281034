import {FrontendName} from '@shared/frontends/frontend_constant';
import {textColor} from '@shared/frontends/frontend_theme_utils';
import {createTheme} from '@shared/frontends/theme_context';

import {createButton} from '@shared-frontend/components/core/button';
import {ALL_WIDTH} from '@shared-frontend/components/core/image';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {createUseResponsive} from '@shared-frontend/lib/use_responsive';

import {baseTheme, Colors} from '@src/components/theme_base';

export const theme = createTheme(FrontendName.Restaurant, baseTheme, {
  logo: <SvgIcon name="Restaurant" color={Colors.Orange} noSize />,
});

export const MenuLink = createButton({
  type: 'a',
  theme: 'link',
  themeOverrides: {
    ...textColor('inherit'),
    textDecorationHover: 'underline',
    backgroundActive: 'transparent',
    backgroundDisabled: 'transparent',
    backgroundHover: 'transparent',
    backgroundLoading: 'transparent',
    focusTextDecoration: 'underline',
    textUnderlineOffset: 8,
    paddingTop: 32,
    paddingRight: 16,
    paddingBottom: 32,
    paddingLeft: 16,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 5,
  },
});

export const {useResponsive, ScreenSize} = createUseResponsive({
  Mobile: 750,
  Desktop: ALL_WIDTH,
});
