import {useScreenSize} from '@shared-frontend/lib/use_screen_size';

export function createUseResponsive<ScreenSize extends Record<string, number>>(
  sizes: ScreenSize
): {
  useResponsive: () => Record<keyof ScreenSize, boolean>;
  ScreenSize: ScreenSize;
} {
  const orderedScreenSizes = Object.entries(sizes).sort((s1, s2) => s1[1] - s2[1]);
  return {
    useResponsive: () => {
      const screenSize = useScreenSize();
      const responsiveInfo = Object.fromEntries(
        orderedScreenSizes.map(([key]) => [key, false])
      ) as Record<keyof ScreenSize, boolean>;
      for (const [screenSizeName, threshold] of orderedScreenSizes) {
        if (screenSize <= threshold) {
          responsiveInfo[screenSizeName as keyof ScreenSize] = true;
          break;
        }
      }
      return responsiveInfo;
    },
    ScreenSize: sizes,
  };
}
