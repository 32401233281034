import {FC} from 'react';

import {Image} from '@shared-frontend/components/core/image';

import Outside from '@src/images/outside.jpg';

export const OutsidePhotoSection: FC = () => {
  return (
    <Image
      srcAndSizes={{srcData: Outside, width: '100vw'}}
      alt="Vue du restaurant"
      width="100%"
      height="378px"
      cover
      brightness={50}
    />
  );
};
OutsidePhotoSection.displayName = 'OutsidePhotoSection';
