import {useCallback, useEffect, useState} from 'react';

export function useHasScrolled(offset = 0): boolean {
  if (!IS_BROWSER) {
    return false;
  }
  /* eslint-disable react-hooks/rules-of-hooks */
  const [hasScrolled, setHasScrolled] = useState(false);

  const checkScroll = useCallback(() => {
    setHasScrolled(window.scrollY > offset);
  }, [offset]);

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
    checkScroll();
    return () => window.removeEventListener('scroll', checkScroll);
  }, [checkScroll]);
  /* eslint-enable react-hooks/rules-of-hooks */

  return hasScrolled;
}
