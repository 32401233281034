import {FC} from 'react';
import styled from 'styled-components';

import {Image} from '@shared-frontend/components/core/image';

import {ScreenSize} from '@src/components/theme';
import FoodOysters from '@src/images/food_oysters.jpg';
import FoodSalad from '@src/images/food_salad.png';

export const PlatePhotosSection: FC = () => {
  return (
    <Wrapper>
      <Image
        srcAndSizes={{
          srcData: FoodOysters,
          width: '48vw',
          thresholds: {[ScreenSize.Mobile]: '100vw'},
        }}
        alt="Plateau d'huîtres"
        width="100%"
      />
      <Image
        srcAndSizes={{
          srcData: FoodSalad,
          width: '48vw',
          thresholds: {[ScreenSize.Mobile]: '100vw'},
        }}
        alt="Salade de feta"
        width="100%"
      />
    </Wrapper>
  );
};
PlatePhotosSection.displayName = 'PlatePhotosSection';

const Wrapper = styled.div`
  display: flex;
  gap: 4vw;
  @media (max-width: ${ScreenSize.Mobile}px) {
    flex-direction: column;
  }
`;
