import {FC} from 'react';
import styled from 'styled-components';

import {Button} from '@shared-frontend/components/core/button';

import {ScreenSize} from '@src/components/theme';

export const CartesMenusSection: FC = () => {
  return (
    <Wrapper>
      <Title>Nos cartes et menus</Title>
      <Blocks>
        <Block>
          <BlockText>
            Découvrez notre menu d'hiver élaboré à partir de produits frais, locaux et de saison.
          </BlockText>
          <Button>MENU HIVER</Button>
        </Block>
        <Block>
          <BlockText>
            Une envie particulière ? Notre carte complète répondra à toutes vos attentes.
          </BlockText>
          <Button>À LA CARTE</Button>
        </Block>
      </Blocks>
    </Wrapper>
  );
};
CartesMenusSection.displayName = 'CartesMenusSection';

const Wrapper = styled.div`
  padding: calc(32px + 1vw);
  text-align: left;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 32px;
`;

const Blocks = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 4vw;
  font-weight: 400;
  font-size: 18px;
  @media (max-width: ${ScreenSize.Mobile}px) {
    flex-direction: column;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const BlockText = styled.div`
  margin-bottom: 8px;
`;
