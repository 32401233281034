// Part of the theme that does not import react so we can use it in the backend
// with the file `frontend_theme_registry_no_react.ts`

import {background, textColor} from '@shared/frontends/frontend_theme_utils';

export const Colors = {
  Orange: '#be8614',
};

export const baseTheme = {
  main: {
    textColor: '#333333',
    lineHeight: 1.7,
    fontFamily: `'Playfair Display', Roboto, Helvetica, sans-serif`,
  },
  button: {
    ...textColor('#000000'),
    textColorLoading: 'transparent',
    ...background('#ffffff'),
    textColorHover: '#ffffff',
    backgroundHover: Colors.Orange,
    borderColorActive: '#000000',
    borderColorHover: Colors.Orange,
    focusBorderColor: Colors.Orange,
    loaderColor: '#00000044',
    paddingTop: 24,
    paddingRight: 48,
    paddingBottom: 24,
    paddingLeft: 48,
    fontSize: 18,
    fontWeight: 600,
    borderRadius: 0,
  },
  link: {
    ...textColor('#000000'),
  },
  input: {
    fontSize: 16,
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 8,
    paddingLeft: 16,
    hoverBorderColor: Colors.Orange,
    focusBorderColor: Colors.Orange,
    focusOutlineColor: `${Colors.Orange}88`,
    borderRadius: 0,
  },
  textarea: {
    fontSize: 16,
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 8,
    paddingLeft: 16,
    hoverBorderColor: Colors.Orange,
    focusBorderColor: Colors.Orange,
    focusOutlineColor: `${Colors.Orange}88`,
    borderRadius: 0,
  },
};
