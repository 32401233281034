import {FC} from 'react';
import styled, {keyframes} from 'styled-components';

import {Image} from '@shared-frontend/components/core/image';
import {cssTransition, TransitionDuration} from '@shared-frontend/components/transitions';

import {ScreenSize} from '@src/components/theme';
import BackgroundImg from '@src/images/background.jpg';

export const TopSection: FC = () => {
  return (
    <Wrapper>
      <BackgroundImage
        srcAndSizes={{srcData: BackgroundImg, width: '100vw'}}
        alt="Notre restaurant"
        width="100%"
        brightness={25}
      />
      <Sentence>
        <FirstPart>Une expérience.</FirstPart>
        <SecondPart>Un sentiment.</SecondPart>
      </Sentence>
    </Wrapper>
  );
};
TopSection.displayName = 'TopSection';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  ${cssTransition(['height'], {duration: TransitionDuration.Long})}
  @media (max-width: ${ScreenSize.Mobile}px) {
    height: 60vh;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackgroundImage = styled(Image)`
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const Sentence = styled.div`
  position: absolute;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 32px;
  color: white;
  font-size: 50px;
  letter-spacing: 3px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FirstPart = styled.h2`
  margin-right: 20px;
  opacity: 0;
  animation: ${fadeIn} 2s ease 1s 1 forwards;
`;

const SecondPart = styled.h2`
  opacity: 0;
  animation: ${fadeIn} 2s ease 2s 1 forwards;
`;
