import {FC} from 'react';
import styled from 'styled-components';

import {Link} from '@shared-frontend/components/core/button';
import {Image} from '@shared-frontend/components/core/image';

import Maps from '@src/images/maps.png';

export const ContactAccesSection: FC = () => {
  return (
    <Wrapper>
      <Title>Contact et accès</Title>
      <Content>
        <ContentLeft>
          <Contact>
            <Address>
              <Subtitle>ADRESSE</Subtitle>
              <div>13 Rue Mercière</div>
              <div>69003 Lyon</div>
            </Address>
            <Subtitle>CONTACT</Subtitle>
            <Link href="tel:+33475349622">04 75 34 96 22</Link>
            <Link href="#contact">Nous contacter</Link>
          </Contact>
          <Hours>
            <Subtitle>HEURES D'OUVERTURE</Subtitle>
            <HoursLine>
              <div>Lundi</div>
              <div>Fermé</div>
            </HoursLine>
            <HoursLine>
              <div>Mardi</div>
              <div>
                <div>12:00 — 14:00</div>
                <div>19:00 — 22:30</div>
              </div>
            </HoursLine>
            <HoursLine>
              <div>Mercredi</div>
              <div>
                <div>12:00 — 14:00</div>
                <div>19:00 — 22:30</div>
              </div>
            </HoursLine>
            <HoursLine>
              <div>Jeudi</div>
              <div>
                <div>12:00 — 14:00</div>
                <div>19:00 — 22:30</div>
              </div>
            </HoursLine>
            <HoursLine>
              <div>Vendredi</div>
              <div>
                <div>12:00 — 14:00</div>
                <div>19:00 — 22:30</div>
              </div>
            </HoursLine>
            <HoursLine>
              <div>Samedi</div>
              <div>
                <div>12:00 — 14:00</div>
                <div>19:00 — 22:30</div>
              </div>
            </HoursLine>
            <HoursLine>
              <div>Dimanche</div>
              <div>Fermé</div>
            </HoursLine>
          </Hours>
        </ContentLeft>
        <ContentRight>
          <Image
            srcAndSizes={{
              srcData: Maps,
              width: 800,
              thresholds: {1024: 800, 1400: `calc(90vw - 500px)`},
            }}
            alt="Map vers le restaurant"
            width="100%"
          />
        </ContentRight>
      </Content>
    </Wrapper>
  );
};
ContactAccesSection.displayName = 'ContactAccesSection';

const Wrapper = styled.div`
  padding: calc(32px + 1vw);
  text-align: left;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 64px;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;
const ContentLeft = styled.div`
  display: flex;
  gap: 64px;
  flex-shrink: 0;
`;
const ContentRight = styled.div`
  flex-grow: 1;
  max-width: 800px;
  * {
    border-radius: 32px;
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 32px;
`;

const Subtitle = styled.div`
  font-weight: 600;
  font-size: 17px;
  margin: 16px 0 4px 0;
`;

const Address = styled.div``;

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Hours = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const HoursLine = styled.div`
  display: flex;
  width: 256px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  border-top: solid 1px #ddd;
`;
