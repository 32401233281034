import {FC} from 'react';
import styled from 'styled-components';

import {Button} from '@shared-frontend/components/core/button';

export const RestaurantPresentationSection: FC = () => {
  return (
    <Wrapper>
      <Title>Générateur de gourmandises</Title>
      <Presentation>
        Envie de découvrir une cuisine généreuse et simple élaboré à base de produits frais? C'est à
        l'atelier des saveurs que vous trouverez votre bonheur. Stéphane et son équipe seront
        heureux de vous recevoir pour passer un moment de partage et de convivialité. Ce restaurant
        situé au coeur du sixième arrondissement de Lyon bénéficie d'un accès simple par les
        transports en commun mais aussi une possibilité de stationner votre véhicule dans le parking
        couvert Morand.
      </Presentation>
      <Button>Réserver maintenant</Button>
    </Wrapper>
  );
};
RestaurantPresentationSection.displayName = 'RestaurantPresentationSection';

const Wrapper = styled.div`
  padding: calc(32px + 1vw);
  text-align: center;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 32px;
`;

const Presentation = styled.div`
  max-width: 800px;
  margin: 16px auto 32px auto;
  font-weight: 400;
  font-size: 18px;
`;
