import {FC} from 'react';
import {useLocation} from 'react-router';
import styled from 'styled-components';

import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {cssTransition, TransitionDuration} from '@shared-frontend/components/transitions';
import {useHasScrolled} from '@shared-frontend/lib/use_has_scrolled';

import {MenuLink, ScreenSize} from '@src/components/theme';
import {Colors} from '@src/components/theme_base';

export const NavBar: FC = () => {
  const {pathname} = useLocation();
  const hasScrolled = useHasScrolled() || pathname !== '/';
  return (
    <Wrapper $hasScrolled={hasScrolled}>
      <Content>
        <LogoLink href="/#top">
          <SvgIcon name="Restaurant" color={hasScrolled ? Colors.Orange : '#ffffff'} height={50} />
        </LogoLink>
        <RightMenu>
          <AnimatedMenuLink $hasScrolled={hasScrolled} href="#menu">
            MENU
          </AnimatedMenuLink>
          <AnimatedMenuLink $hasScrolled={hasScrolled} href="#reservation">
            RÉSERVATION
          </AnimatedMenuLink>
          <AnimatedMenuLink $hasScrolled={hasScrolled} href="#contact">
            CONTACT
          </AnimatedMenuLink>
          <AnimatedMenuLink $hasScrolled={hasScrolled} href="#acces">
            ACCÈS
          </AnimatedMenuLink>
        </RightMenu>
      </Content>
    </Wrapper>
  );
};
NavBar.displayName = 'NavBar';

// flex-direction: column;
// align-items: flex-end;
// justify-content: flex-start;
// background: #160a00dd;
// position: fixed;
// top: 0;
// right: 0;

export const TOP_BAR_HEIGHT_DESKTOP = 90;
export const TOP_BAR_HEIGHT_MOBILE = 70;

const AnimatedMenuLink = styled(MenuLink)<{$hasScrolled: boolean}>`
  padding: 0;
  margin: 32px 16px;
  display: inline-block;
  position: relative;
  text-decoration: none !important;
  :after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: -5px;
    right: 5px;
    height: 0;
    background: ${p => (p.$hasScrolled ? Colors.Orange : '#ffffff')};
    transition: bottom 400ms;
  }
  :hover:after,
  :focus:after {
    bottom: 0;
    height: 1px;
  }
`;

const Wrapper = styled.div<{$hasScrolled: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${TOP_BAR_HEIGHT_DESKTOP}px;
  padding: 0 30px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  ${p =>
    p.$hasScrolled
      ? `
    background-color: #ffffff;
    color: ${Colors.Orange};
    box-shadow: rgb(0 0 0 / 30%) 0 1px 3px;
    `
      : `
    background-color: transparent;
    color: #ffffff;
    box-shadow: none;
  `};
  ${cssTransition(['background-color', 'color', 'box-shadow', 'height'], {
    duration: TransitionDuration.Long,
  })}

  @media (max-width: ${ScreenSize.Mobile}px) {
    height: ${TOP_BAR_HEIGHT_MOBILE}px;
  }
`;

// max-width: ${MAX_WIDTH}px;
const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${ScreenSize.Mobile}px) {
    gap: 6px 0;
  }
`;

const RightMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 0 16px;
`;

const LogoLink = styled.a``;
